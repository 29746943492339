import { useState, useEffect, useCallback } from 'react';
import { useObserver } from 'mobx-react-lite';
import useUI from 'src/hooks/use-ui';

export enum CarouselStates {
  loading = 'loading',
  rendered = 'rendered',
}

type UseHybridCarouselRenderedParams = {
  loading: boolean;
  hasBeenVisible: boolean;
  onRender?: (value: CarouselStates) => void;
};

export function useHybridCarouselRendered({
  loading,
  hasBeenVisible,
  onRender,
}: UseHybridCarouselRenderedParams): void {
  const [state, setState] = useState<CarouselStates>(CarouselStates.loading);

  useEffect(() => {
    if (!loading && hasBeenVisible) {
      setState(CarouselStates.rendered);
    }
  }, [loading, hasBeenVisible]);

  useEffect(() => {
    if (!onRender) {
      return;
    }
    onRender(state);
  }, [onRender, state]);
}

type UseCarouselLifecycleReturn = {
  loading: boolean;
  rendered: boolean;
  onRenderCarousel: (carouselState: CarouselStates) => void;
};

export function useCarouselLifecycle(): UseCarouselLifecycleReturn {
  const [lifecycle, setLifecycle] = useState({
    loading: false,
    rendered: false,
  });
  const UI = useUI();
  const href = useObserver(() => UI.href);

  useEffect(() => {
    setLifecycle({ loading: false, rendered: false });
  }, [href]);

  const onRenderCarousel = useCallback((carouselState: CarouselStates) => {
    setLifecycle({
      loading: carouselState === CarouselStates.loading,
      rendered: carouselState === CarouselStates.rendered,
    });
  }, []);

  return {
    ...lifecycle,
    onRenderCarousel,
  };
}
