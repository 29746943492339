import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { tracker } from 'src/utils/analytics';

// FIXME: we are not suppose to import things form the internal of the analytics framework. we need to rework the trackerSource implementation to make is agnostic of GA
import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';

import { GqlGetSponsoredBrandQuery, GqlAdTrackers } from 'types/graphql';
import { Product, parseProduct } from 'src/utils/helpers/product';
import { useQuickAddToCart } from 'src/components/product-carousel/use-quick-add-to-cart';
import { useFetchTracker } from 'src/utils/ads/hooks/use-fetch-tracker';
import { ProductPlacement } from 'src/types';

type SponsoredBrandData = {
  campaignId: string | undefined;
  brandName: string | undefined;
  globalTrackers: GqlAdTrackers | undefined;
  products: Array<Product>;
};

export function useSponsoredBrandData(data: GqlGetSponsoredBrandQuery | null | undefined): SponsoredBrandData {
  const { name: brandName, campaignId, products = [], trackers: globalTrackers } = data?.getSponsoredBrand ?? {};

  const normalizedProducts = useMemo(() => products.map((item): Product => parseProduct(item)), [products]);

  return {
    brandName,
    campaignId,
    globalTrackers,
    products: normalizedProducts,
  };
}

type SponsoredBrandHandlersParams = SponsoredBrandData & {
  hasBeenVisible: boolean;
  placement: ProductPlacement;
};

type SponsoredBrandHandlers = {
  handleProductClick: (product: Product, index: number) => void;
  handleQuickAddToCart: (product: Product) => void;
  handleProductImpression: (product: Product) => void;
  handleViewAllClick: () => void;
};

export function useSponsoredBrandHandlers({
  products = [],
  campaignId,
  globalTrackers,
  hasBeenVisible,
  placement,
}: SponsoredBrandHandlersParams): SponsoredBrandHandlers {
  const router = useRouter();
  const bannerImpressionTriggered = useRef(false);
  const { handleAddToCart } = useQuickAddToCart();
  const fetchTracker = useFetchTracker();

  const { impressionUrl, clickUrl } = globalTrackers ?? {};

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.moloco,
    section: ProductRecommendationSection.sponsoredBrand,
  });

  const shouldTriggerBannerImpression = hasBeenVisible && !bannerImpressionTriggered.current && products.length > 0;

  // Sponsored Brand Products view
  const handleBannerImpression = useCallback(() => {
    if (impressionUrl) {
      void fetchTracker(impressionUrl);
      tracker.sponsoredBannerImpression({ impressionUrl, products, campaignId, trackerSource });
    }
  }, [campaignId, fetchTracker, impressionUrl, products, trackerSource]);

  useEffect(() => {
    if (shouldTriggerBannerImpression) {
      handleBannerImpression();
      bannerImpressionTriggered.current = true;
    }
  }, [handleBannerImpression, shouldTriggerBannerImpression]);

  // Sponsored Brand Product card view
  const handleProductImpression = useCallback(
    (product: Product) => {
      if (product.adTrackers?.impressionUrl) {
        void fetchTracker(product.adTrackers.impressionUrl);
        tracker.sponsoredProductImpression({ product, campaignId, trackerSource });
      }
    },
    [fetchTracker, campaignId, trackerSource]
  );

  // Sponsored Brand Product card click
  const handleProductClick = useCallback(
    (product: Product, index: number): void => {
      if (clickUrl) {
        void fetchTracker(clickUrl);
        tracker.sponsoredBannerClicked({ clickUrl, products, campaignId, trackerSource });
      }

      if (product.adTrackers?.clickUrl) {
        void fetchTracker(product.adTrackers.clickUrl);
        tracker.sponsoredProductClicked({ product, campaignId, trackerSource, position: index });
        tracker.setContext({ activePromotion: { product, campaignId, trackerSource } });
      }

      // FIXME: why do we care about trackerSource in productClick?
      tracker.productClicked({ product, position: index, trackerSource, placement });
    },
    [clickUrl, trackerSource, placement, fetchTracker, products, campaignId]
  );

  // Sponsored Brand Product quick-add-to-cart click
  const handleQuickAddToCart = useCallback(
    (product): void => {
      void handleAddToCart(product, trackerSource);
    },
    [handleAddToCart, trackerSource]
  );

  // Sponsored Brand Products 'View All' click
  const handleViewAllClick = useCallback((): void => {
    if (clickUrl) {
      void fetchTracker(clickUrl);
      tracker.sponsoredBannerClicked({ clickUrl, products, campaignId, trackerSource });
    }
  }, [campaignId, clickUrl, fetchTracker, products, trackerSource]);

  return {
    handleProductClick,
    handleProductImpression,
    handleQuickAddToCart,
    handleViewAllClick,
  };
}

export function useHasValidSponsoredBrandPlacement(target: 'category-page' | 'home-page'): boolean {
  const flags = useFlags();
  const config = flags['growth.ads.sponsored-brand-carousel.placement'];

  if (target === 'home-page') {
    return ['home', 'home-category'].includes(config);
  }

  return ['category', 'home-category'].includes(config);
}
