import React from 'react';

type SvgWarningProps = {
  width?: number;
  height?: number;
};

export const SvgEmptyGoodsColorIcon = ({ width = 121, height = 91 }: SvgWarningProps): JSX.Element => (
  <svg width={width} height={height} viewBox='0 0 122 92' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path opacity='0.4' d='M120.3 0.459961V78.8904H1.5V0.459961H120.3Z' fill='#B0C4CC' />
    <rect x='52.1656' y='18.8898' width='14.124' height='14.128' rx='2.55816' fill='white' />
    <rect x='73.3516' y='18.8898' width='14.124' height='14.128' rx='2.55816' fill='white' />
    <rect x='52.1656' y='38.8165' width='14.124' height='14.128' rx='2.55816' fill='#E4EDF0' />
    <rect x='30.9796' y='18.8898' width='14.124' height='14.128' rx='2.55816' fill='white' />
    <rect x='30.9796' y='38.8165' width='14.124' height='14.128' rx='2.55816' fill='#E4EDF0' />
    <rect x='73.3516' y='40.0824' width='14.124' height='14.128' rx='2.55816' fill='white' />
    <rect x='73.3516' y='58.6231' width='14.124' height='14.128' rx='2.55816' fill='#E4EDF0' />
    <rect x='93.9436' y='18.8898' width='14.124' height='14.128' rx='2.55816' fill='white' />
    <rect x='93.9436' y='40.0824' width='14.124' height='14.128' rx='2.55816' fill='white' />
    <rect x='93.9436' y='59.8877' width='14.124' height='14.128' rx='2.55816' fill='#E1EAEE' />
    <path
      d='M116.216 6.2759C115.691 5.53824 114.816 5.13345 113.914 5.21198H32.388C30.8252 5.21198 29.616 6.22178 30.1401 7.31738C30.6641 8.05395 31.5393 8.45918 32.4395 8.3813H113.964C115.531 8.3813 116.741 7.3715 116.216 6.2759Z'
      fill='white'
    />
    <path
      d='M7.83602 9.17462C9.14825 9.17462 10.212 8.11026 10.212 6.7973C10.212 5.48435 9.14825 4.41998 7.83602 4.41998C6.52379 4.41998 5.46002 5.48435 5.46002 6.7973C5.46002 8.11026 6.52379 9.17462 7.83602 9.17462Z'
      fill='white'
    />
    <path
      d='M14.964 9.17462C16.2762 9.17462 17.34 8.11026 17.34 6.7973C17.34 5.48435 16.2762 4.41998 14.964 4.41998C13.6518 4.41998 12.588 5.48435 12.588 6.7973C12.588 8.11026 13.6518 9.17462 14.964 9.17462Z'
      fill='white'
    />
    <path
      d='M22.092 9.17462C23.4042 9.17462 24.468 8.11026 24.468 6.7973C24.468 5.48435 23.4042 4.41998 22.092 4.41998C20.7798 4.41998 19.716 5.48435 19.716 6.7973C19.716 8.11026 20.7798 9.17462 22.092 9.17462Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.2251 22.537H11.4792C10.5723 22.537 9.8371 21.8018 9.8371 20.8949C9.8371 19.988 10.5723 19.2528 11.4792 19.2528H17.2251C18.132 19.2528 18.8672 19.988 18.8672 20.8949C18.8672 21.8018 18.132 22.537 17.2251 22.537ZM18.8659 29.9263H11.4792C10.5723 29.9263 9.8371 29.1911 9.8371 28.2842C9.8371 27.3774 10.5723 26.6422 11.4792 26.6422H18.8659C19.4526 26.6422 19.9947 26.9551 20.288 27.4632C20.5813 27.9713 20.5813 28.5972 20.288 29.1053C19.9947 29.6133 19.4526 29.9263 18.8659 29.9263ZM11.4792 44.9783H18.8659C19.7728 44.9783 20.508 44.2431 20.508 43.3362C20.508 42.4293 19.7728 41.6941 18.8659 41.6941H11.4792C10.5723 41.6941 9.8371 42.4293 9.8371 43.3362C9.8371 44.2431 10.5723 44.9783 11.4792 44.9783ZM17.2251 37.3144H11.4792C10.5908 37.2892 9.88365 36.5617 9.88365 35.6729C9.88365 34.7842 10.5908 34.0567 11.4792 34.0315H17.2251C18.1135 34.0567 18.8207 34.7842 18.8207 35.6729C18.8207 36.5617 18.1135 37.2892 17.2251 37.3144ZM11.4792 52.3676H17.2251C18.132 52.3676 18.8672 51.6325 18.8672 50.7256C18.8672 49.8187 18.132 49.0835 17.2251 49.0835H11.4792C10.5723 49.0835 9.8371 49.8187 9.8371 50.7256C9.8371 51.6325 10.5723 52.3676 11.4792 52.3676Z'
      fill='white'
    />
    <path
      opacity='0.3'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.4805 30.8199H58.1583C58.6137 30.8199 58.9833 31.2318 58.9833 31.74V34.6149H56.4291V40.6447C56.4291 41.5067 55.8246 42.2049 55.0788 42.2049H47.5601C46.8143 42.2049 46.2097 41.5053 46.2097 40.6447V34.6149H43.6542V31.74C43.6542 31.2318 44.0238 30.8199 44.4805 30.8199ZM77.3881 30.8199H64.8507C64.4323 30.8199 64.0931 31.2318 64.0931 31.74V34.6149H78.1458V31.74C78.1458 31.2318 77.8065 30.8199 77.3881 30.8199ZM46.2097 57.3849H56.4291V63.6985C56.4291 64.4034 55.8246 64.9749 55.0788 64.9749H47.5601C46.8143 64.9749 46.2097 64.4034 46.2097 63.6985V57.3849ZM58.1583 52.3254H44.4805C44.0238 52.3254 43.6542 52.7372 43.6542 53.2454V56.1204H58.9833V53.2454C58.9833 52.7372 58.6137 52.3254 58.1583 52.3254ZM65.3708 57.3849H75.5903V63.6985C75.5903 64.4034 74.9857 64.9749 74.2399 64.9749H66.7212C65.9754 64.9749 65.3708 64.4034 65.3708 63.6985V57.3849ZM77.3881 52.3254H64.8507C64.4323 52.3254 64.0931 52.7372 64.0931 53.2454V56.1204H78.1458V53.2454C78.1458 52.7372 77.8065 52.3254 77.3881 52.3254Z'
      fill='#BDCCCE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M82.0068 34.2203V34.3839H82.0081L82.3738 35.2472C84.4765 52.464 87.6828 84.5532 87.6498 86.1108L87.6432 86.4804H23.2153V86.1016C25.8646 61.8056 31.6726 27.8077 40.1113 24.5288L40.1298 25.4423L41.4657 24.4945H80.8558C84.2917 24.4945 87.0875 25.5254 87.0875 28.6525C87.0123 31.4588 84.8699 33.8084 82.0068 34.2203ZM39.3405 26.0033C39.1847 26.0006 38.9893 26.0006 39.0844 26.0072L39.2982 26.0336L39.3405 26.0033Z'
      fill='#F1F0DE'
    />
    <path d='M39.8236 23.8636H41.0987C41.1409 23.857 39.7787 23.8636 39.8236 23.8636Z' fill='#E4EDF0' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.4361 31.3942C33.4361 35.132 37.8123 35.8794 42.189 35.8795L41.8854 31.2701C43.0829 30.1443 43.7274 28.5505 43.6489 26.9088C43.6489 23.919 40.7317 23.1706 38.8468 23.2339C36.9605 23.296 33.4361 27.6572 33.4361 31.3942ZM80.4967 77.4252C81.7692 73.4296 82.3117 69.199 82.0939 64.975L85.8097 86.4804H75.5903C77.7965 83.808 79.4628 80.7327 80.4967 77.4252Z'
      fill='#D7D6C9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.16394 88.166C9.16394 89.0966 9.75266 90.2754 25.0567 90.2754L85.8097 90.2398L81.8181 86.4804H28.8399C15.4735 86.4804 9.16394 87.2354 9.16394 88.166ZM113.913 89.8556C113.913 88.9237 113.476 87.745 102.121 87.745L93.4737 87.7806V91.54H99.3133C109.232 91.54 113.913 90.7862 113.913 89.8556Z'
      fill='#BDCCCE'
    />
    <path
      d='M87.3832 87.745C76.0285 87.745 75.5903 88.9238 75.5903 89.8557C75.5903 90.7863 80.2723 91.54 90.1895 91.54H96.0292V87.7806L87.3832 87.745Z'
      fill='#BDCCCE'
    />
    <path
      d='M37.0278 30.8199C38.266 33.4467 40.4202 29.1739 41.2096 32.3881C44.4818 45.7148 47.4875 78.6409 47.4875 86.4804C43.9684 86.4144 38.2316 86.3484 34.7125 86.2811C34.7125 86.2811 35.8702 33.6078 37.0278 30.8199Z'
      fill='#D7D6C9'
    />
    <path d='M41.1 37.3263V34.615H81.9777L83.34 38.41L41.1 37.3263Z' fill='#B2B19F' />
    <path
      d='M47.6155 86.4804C47.0238 86.4776 46.5307 86.0266 46.475 85.4376C46.4288 84.9809 41.9078 39.6072 39.8882 33.6276C39.7503 33.235 39.8323 32.7988 40.1034 32.4831C40.373 32.1673 40.7928 32.0228 41.1996 32.1056C41.6065 32.1885 41.9363 32.4857 42.061 32.8818C44.1624 39.1122 48.5725 83.3309 48.7586 85.2079C48.7918 85.5319 48.6865 85.8549 48.4688 86.0973C48.2512 86.3396 47.9413 86.4787 47.6155 86.4804Z'
      fill='#405D67'
    />
    <path
      d='M88.3336 87.7423C88.1099 87.7586 87.8878 87.695 87.7066 87.5628H25.9873C25.2189 87.5639 24.4838 87.2488 23.9545 86.6916C23.4327 86.1415 23.1676 85.3965 23.2246 84.6403C23.9374 75.3106 27.9977 28.5007 37.9795 23.23L39.1055 25.2813C37.0859 26.3465 33.1497 31.3533 29.4497 52.9049C27.0156 67.0777 25.8105 81.9726 25.5927 84.8146C25.5843 84.9233 25.6228 85.0303 25.6983 85.1089C25.7735 85.1881 25.8791 85.233 25.9887 85.2317H87.1311L87.1165 85.0773C85.1748 65.0542 83.3572 47.7714 81.7177 33.7081L84.0779 33.4428C85.72 47.5219 87.5376 64.8245 89.482 84.8568L89.5189 85.2304H89.5321L89.6259 86.2943C89.6259 86.3299 89.6311 86.3643 89.6325 86.3999V86.4289C89.6729 86.7426 89.5816 87.0589 89.3803 87.3028C89.1767 87.5496 88.8821 87.7038 88.5633 87.7305C88.4788 87.7397 88.4049 87.7437 88.3336 87.7437V87.7423Z'
      fill='#405D67'
    />
    <path
      d='M82.0833 33.3504H42.2721C41.6253 33.3504 41.1 32.7828 41.1 32.0845C41.1 31.3862 41.624 30.8199 42.2708 30.8199H82.0833C82.7301 30.8199 83.2542 31.3875 83.2542 32.0845C83.2542 32.7841 82.7301 33.3504 82.0833 33.3504Z'
      fill='#405D67'
    />
    <path
      d='M83.1394 69.9645H106.365V85.8943C106.365 87.6539 104.979 89.0808 103.268 89.0808H86.2361C84.5254 89.0808 83.1394 87.6539 83.1394 85.8943V69.9645Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M86.2361 90.2754H103.268C105.619 90.2728 107.524 88.3126 107.526 85.8943V69.9646C107.526 69.3046 107.006 68.77 106.365 68.77H83.1394C82.4978 68.77 81.9778 69.3046 81.9778 69.9646V85.8943C81.9804 88.3126 83.8852 90.2728 86.2361 90.2754ZM84.2996 85.8943V71.1592H105.203V85.8943C105.203 86.9939 104.337 87.8849 103.268 87.8849H86.2361C85.1669 87.8849 84.2996 86.9939 84.2996 85.8943Z'
      fill='#405D67'
    />
    <path
      d='M38.4692 23.7963C41.6412 23.7263 44.2759 26.0205 44.3564 28.9231C44.3564 31.7532 42.5216 34.0487 39.2692 34.0487H83.4324C85.4995 34.1398 87.4531 33.1828 88.5144 31.5592C89.5774 29.9621 89.5774 27.8829 88.5144 26.2858C87.4531 24.6622 85.4995 23.7052 83.4324 23.7963H39.2692'
      fill='#D7D6C9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.2678 35.6855H83.4324C85.9298 35.7779 88.2821 34.6123 89.5625 32.6468C90.8415 30.7141 90.8415 28.204 89.5625 26.2712C88.2834 24.3084 85.9298 23.1428 83.4324 23.2352H39.2678C39.1913 23.2352 39.1147 23.2418 39.0381 23.255C38.8494 23.2418 38.6593 23.2352 38.4666 23.2352C37.8039 23.2352 37.2654 23.7263 37.2654 24.3335C37.2654 24.9407 37.8039 25.4317 38.4666 25.4317C40.9746 25.367 43.0694 27.1675 43.1526 29.4603C43.1526 31.3176 42.1349 33.4877 39.2678 33.4877C38.6052 33.4877 38.0666 33.98 38.0666 34.5872C38.0666 35.1931 38.6052 35.6855 39.2678 35.6855ZM83.4324 33.4863H44.2152C45.1143 32.3393 45.5882 30.9161 45.5563 29.459C45.5523 27.9727 44.9517 26.5392 43.8667 25.4304H83.4324C85.0718 25.3353 86.6334 26.0825 87.4848 27.3681C88.3359 28.6321 88.3359 30.2859 87.4848 31.5499C86.6334 32.8356 85.0718 33.5814 83.4324 33.4863Z'
      fill='#405D67'
    />
    <path
      d='M83.4786 62.2875H107.302C108.178 62.2875 108.89 62.9475 108.89 63.7632V68.9284H81.8906V63.7632C81.8906 62.9475 82.6021 62.2875 83.4786 62.2875Z'
      fill='#BDCCCE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M81.8907 70.0345H108.89C109.201 70.0364 109.502 69.921 109.732 69.7111C109.952 69.5102 110.079 69.2264 110.081 68.9283V63.7632C110.081 62.3363 108.836 61.1799 107.302 61.1799H83.4786C81.9448 61.1799 80.7 62.3363 80.7 63.7632V68.9283C80.7 69.5395 81.2333 70.0345 81.8907 70.0345ZM107.698 67.8209H83.0826V63.7632C83.0826 63.5586 83.2595 63.3936 83.4786 63.3936H107.302C107.521 63.3936 107.698 63.5586 107.698 63.7632V67.8209Z'
      fill='#405D67'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M88.3639 70.0345C87.6591 70.0345 87.0875 69.5395 87.0875 68.9283V62.2874C87.0875 61.6749 87.6591 61.1799 88.3639 61.1799C89.0701 61.1799 89.6417 61.6749 89.6417 62.2874V68.927C89.6417 69.5395 89.0701 70.0358 88.3639 70.0358V70.0345ZM94.7514 70.0345C94.0465 70.0345 93.4737 69.5395 93.4737 68.9283V62.2874C93.4737 61.6749 94.0465 61.1799 94.7514 61.1799C95.4576 61.1799 96.0292 61.6749 96.0292 62.2874V68.927C96.0292 69.5395 95.4576 70.0358 94.7514 70.0358V70.0345ZM101.139 68.9283C101.139 69.5395 101.71 70.0345 102.417 70.0345V70.0358C103.122 70.0358 103.693 69.5395 103.693 68.927V62.2874C103.693 61.6749 103.122 61.1799 102.417 61.1799C101.71 61.1799 101.139 61.6749 101.139 62.2874V68.9283Z'
      fill='#405D67'
    />
    <path d='M84.3828 77.4991H94.9032V82.8121H84.3828L84.3828 77.4991Z' fill='#7F9AA2' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M83.2542 83.95H96.0292V76.36H83.2542V83.95ZM93.7746 81.673H85.5088V78.637H93.7746V81.673Z'
      fill='#405D67'
    />
    <path d='M84.2812 72.0369V70.2299H105.203V72.788L84.2812 72.0369Z' fill='#BDCCCE' />
    <path
      d='M39.3127 34.6942C38.7583 34.7166 38.2303 34.4671 37.9069 34.0289C37.5842 33.5941 37.5098 33.0231 37.7103 32.5201L42.9005 31.1368L39.3127 34.6942Z'
      fill='#405D67'
    />
    <path
      d='M39.3114 35.8795C38.4285 35.8718 37.5887 35.4968 36.9935 34.8447C36.4262 34.1107 36.2501 33.1473 36.5209 32.2601C36.6232 31.8265 36.957 31.485 37.3882 31.3731L43.6542 29.5554C44.1875 29.4115 43.6753 30.0702 43.9552 30.5322C44.2346 30.9949 44.158 31.5891 43.7704 31.9657L41.1 35.8795C40.8703 36.1079 39.6388 35.8795 39.3114 35.8795Z'
      fill='#405D67'
    />
    <path
      d='M34.8749 86.4804C34.0829 86.4804 33.4401 85.9762 33.4401 85.3545C33.4348 84.0952 33.3331 54.4322 34.4037 45.7518C34.4538 45.3519 34.7719 45.0021 35.2379 44.8358C35.7086 44.6695 36.2276 44.7093 36.6675 44.9453C37.0846 45.179 37.3103 45.5697 37.2615 45.9696C36.2041 54.5364 36.3111 85.0403 36.3111 85.3466C36.3111 85.9683 35.6682 86.4725 34.8749 86.4725V86.4804Z'
      fill='#405D67'
    />
  </svg>
);
