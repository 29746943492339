import React from 'react';
import { SvgEmptyGoodsColorIcon } from 'assets/empty-goods-color-icon';
import styled from 'styled-components';
import { Button } from 'shared/components';
import { focusStyles } from 'src/theme';

type CarouselViewAllProps = {
  brandName: string;
  href: string;
};

export function CarouselViewAll({ brandName, href }: CarouselViewAllProps): JSX.Element {
  function trimBrandName(str: string): string {
    return str.length > 18 ? `${str.substring(0, 18)}...` : str;
  }

  return (
    <CarouselViewAllContainer>
      <ContentContainer>
        <SvgEmptyGoodsColorIcon />
        <ViewAllButton onClick={() => (window.location.href = href)}>
          {`View all ${trimBrandName(brandName).toLowerCase()}`}
        </ViewAllButton>
      </ContentContainer>
    </CarouselViewAllContainer>
  );
}

const CarouselViewAllContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 54px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .focus-visible {
    ${focusStyles}
  }
`;

const ViewAllButton = styled(Button)`
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  margin-top: 20px;
  max-width: 285px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
